<template>
  <section class="blank-page">
    <pageLoader
      v-if="activloader"
      :loadingText="'Traitement des données en cours'"
    />

    <div class="page-header">
      <h3 class="page-title">Ajout d'une catégorie</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">e-Commerce</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Ajout d'une catégorie
          </li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- snotify -->
          <!-- <button @click="showSnotify()">snotify</button>
                <simpleSnotifysuccess /> -->

          <div class="card-body">
            <!-- ajout catalogue -->
            <div class="row">
              <div class="col-md-6">
                <form class="forms-sample" @submit.prevent="handleSubmit">
                  <!-- category name -->
                  <b-form-group
                    id="category"
                    label="Nom de la catégorie"
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      v-model="category"
                      :state="$v.category.$dirty ? !$v.category.$error : null"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- description -->
                  <b-form-group
                    id="description"
                    label="Description"
                    label-for="example-input-1"
                  >
                    <b-form-textarea
                      id="example-input-1"
                      name="example-input-1"
                      v-model="description"
                      rows="6"
                      :state="
                        $v.description.$dirty ? !$v.description.$error : null
                      "
                      aria-describedby="input-1-live-feedback"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire.
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <div class="d-flex">
                    <b-button type="submit" variant="success" class="mr-2"
                      >Valider</b-button
                    >
                    <b-button
                      variant="light"
                      class="mr-2"
                      @click="$router.go(-1) && showSnotify()"
                      >Annuler</b-button
                    >
                  </div>
                </form>
              </div>
            </div>
            <!-- Modal Ends -->
          </div>
          <!-- <button @click="showAlert()">test</button> -->
          <!-- <button @click="showSnotify()">cliquer pour la notification</button> -->
          <vue-snotify></vue-snotify>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require("axios").default;
import { validationMixin } from "vuelidate";
// import { required, minLength, alphaNum, numeric } from 'vuelidate/lib/validators'
import { required, minLength, maxLength } from "vuelidate/lib/validators";
// import alertPrompt from '../../components/alerts/sweet-alert/alertPrompt.vue'
import pageLoader from "../../components/pageLoader.vue";

export default {
  components: {
    pageLoader,
  },
  name: "addCatalogue",
  mixins: [validationMixin],
  data() {
    return {
      ulid: null,
      category: "",
      description: "",
      activloader: false,
    };
  },
  validations: {
    category: {
      required,
      minLength: minLength(4),
    },
    description: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(100),
    },
  },
  computed: {},
  methods: {
    //confirmation
    showAlert () {
      this.$swal({
        title: "Confirmation",
        text: "Voulez-vous vraiment valider cette catégorie?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value && !this.ulid) {
          this.addCategory()
          this.$swal(
            'Ajouté!',
            'Votre catégorie a bien été ajoutée avec succès!',
            'success'
          )
        }
        else if(result.value && this.ulid) {
          this.addCategory()
          this.$swal(
            'Modifié!',
            'Votre catégorie a bien été modifiée avec succès!',
            'success'
          )
        }
        else {
          this.activloader = false;
        }
      })
    },

    // add products
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.showAlert()
    },
    addCategory(){
      // si POST (ulid vide)
      if (!this.ulid) {
        axios
          .post(
            "categories",
            {
              category: this.category,
              description: this.description,
            },
            {
              headers: {
                "X-AUTH-TOKEN": localStorage.getItem("token"),
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            //console.log ('response add product', res)
            this.activloader = false;
            res;
            this.showSnotify();
            this.$router.push({ name: "category" });
          })
          .catch((err) => {
            console.log("response err product", err)
            this.activloader = false;
            });
      }
      // PUT
      else {
        // alert('PUT')
        console.log(this.activloader)
        axios
          .put(
            `categories/${this.ulid}`,
            {
              category: this.category,
              description: this.description,
              datepublished: this.datepublished,
              datemodified: this.datemodified,
            },
            {
              headers: {
                "X-AUTH-TOKEN": localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.activloader = false;
            this.$router.push({
              name: "category",
            });
          })
          .catch((err) => {
            this.activloader = false;
            console.log("errorPUT", err)});
      }
    },
    // getProduct to edit params via catalogue
    getCategory() {
      if (this.ulid) {
        this.activloader = true;
        axios
          .get(`categories/${this.ulid}`)
          .then((res) => {
            console.log("get response product", res.data),
              (this.putProduct = true);
            //insert result in data
            this.category = res.data.category;
            this.description = res.data.description;
            this.activloader = false;
          })
          .catch((err) => {
            console.log("errorgetcatégory", err);
            this.activloader = false;
          });
      }
    },
    async showSnotify() {
      this.$snotify.success("Ajouté avec succès", {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
      });
    },
  },
  mounted() {
    // call edit product params
    this.category = this.$route.params.category;
    this.ulid = this.$route.params.ulid;
    // format date
    (this.datepublished = new Date()
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "")),
      (this.datemodified = new Date()
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "")),
      //charge product via methods
      this.getCategory();
  },
};
</script>